import React from "react"
import {Form,Button,Card} from "react-bootstrap"
import Layout from "../components/layout"

export default () => (
<Layout>
<a href="https://biniljacob.com"><h4> &#60; Back</h4></a>
<Card >
  <Card.Body>
    
    <h2>Write to me</h2>
    
    <Form name="contact"
    method="post"
    action="/success"
    data-netlify="true"
    data-netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field" />
        <Form.Group controlId="formBasicEmail" >
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" name="email" placeholder="Enter email" />
            <Form.Text className="text-muted">
            
            </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicText">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" name="name" id="name" placeholder="Your name"/>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Example textarea</Form.Label>
            <Form.Control as="textarea" rows="3" type="text" name="msg" placeholder="Message" />
        </Form.Group>
        <Button variant="primary" type="submit">
            Submit
        </Button>
    </Form>





  </Card.Body>
</Card>



  


  </Layout>
)