import React from "react"
import SEO from "../components/seo"
import Header from "../components/header"
import DummyForm from "../components/dummyForm"
import 'bootstrap/dist/css/bootstrap.css';
export default () => (
  <div>
    <SEO title="Binil Jacob" />
    <Header/>
    <DummyForm/>
  </div>
)